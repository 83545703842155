import React, {useState } from "react";
import "./App.css";
import {theme} from "./themeApp"
import {useStyles} from "./themeApp"
import { Snackbar, ThemeProvider, CssBaseline, LinearProgress, Backdrop, Grid} from "@material-ui/core";
import {Alert} from "./components/Alert/Alert"
import {SuccessAlert} from "./components/Alert/Alert"
import {AppDialog} from "./components/Modals/Dialog"
import { TERMS_TEXT,ENV } from "./constants";
import { CoescoLogo, IALogo, FinalLogoIA, WhiteDominusLogo } from "./components/Icons/CustomIcons";
import {FormPerson} from "./components/Forms/FormPerson"


function App() {

  const classes = useStyles();
  const [sendingData, setsendingData] = useState(false);

  const [showErrorSnackbar, setshowErrorSnackbar] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  const [showInfoSnackbar, setshowInfoSnackbar] = useState(false);
  const [infoMessage, setinfoMessage] = useState("");

  const [showDialog, setshowDialog] = useState(false);

  function handlerSendingData(value){ setsendingData(value)}
  function handlerShowErrorSnackbar(value){ setshowErrorSnackbar(value)}
  function handlerErrorMessage(value){ seterrorMessage(value)}
  function handlerShowInfoSnackbar(value){ setshowInfoSnackbar(value)}
  function handlerInfoMessage(value){ setinfoMessage(value)}
  function handlerShowDialog(value){ setshowDialog(value)}

  return (
    <ThemeProvider theme={theme}>

      <CssBaseline />

      <div className={classes.app}>

        {/* BARRA DE CARGA*/}
        <Backdrop style={{position: "fixed", zIndex: 100}} open={sendingData}>
          <div style={{ width: "100%", position: "absolute", top: 0, left: 0 }}>
            <LinearProgress color="primary" />
          </div>
        </Backdrop>

        {/* BARRA MENSAJES DE ERROR*/}
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={6000} open={showErrorSnackbar} onClose={() => setshowErrorSnackbar(false)}>
          <Alert severity="error" onClose={() => setshowErrorSnackbar(false)}>
            {errorMessage}
          </Alert>
        </Snackbar>

        {/* BARRA MENSAJES DE INFORMACIÓN*/}
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={6000} open={showInfoSnackbar} onClose={() => setshowInfoSnackbar(false)}>
          <SuccessAlert color="success" variant="standard" classes={classes.successAlert} onClose={() => setshowInfoSnackbar(false)}>
            {infoMessage}
          </SuccessAlert>
        </Snackbar>

        {/*DIALOGO DE LOS TERMINOS*/}
        <AppDialog title="Términos" open={showDialog} text={TERMS_TEXT} onClose={() => setshowDialog(false)} onConfirm={() => {setshowDialog(false)}}/>

        <Grid container id="app-header" className={classes.appHeader} direction="row" justify="space-between" alignContent="center" >

          <Grid item xs={6} md={3}>
            <Grid item style={{margin: (ENV == 'coesco' || ENV == 'coescotesting') ? "0px" : "5px 2px"}}>
              {(ENV == 'coesco' || ENV== 'coescotesting') ? 
                (<CoescoLogo/>):
                (<FinalLogoIA/>) 
              }
            </Grid>
          </Grid>

          <Grid item xs={6} md={6}>
            <Grid container justify="center">
              <h2 className={classes.headerText}>Registro de Personas</h2>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}></Grid>
        </Grid>

        {/*FORMULARIO DE PERSONA*/}
        <FormPerson 
          handlerSendingData={handlerSendingData}
          handlerShowErrorSnackbar={handlerShowErrorSnackbar}
          handlerErrorMessage={handlerErrorMessage}
          handlerShowInfoSnackbar={handlerShowInfoSnackbar}
          handlerInfoMessage={handlerInfoMessage}
          handlerShowDialog={handlerShowDialog}
          sendingData={sendingData}>
        </FormPerson>
        
        <div className="container" style={{width:"70%",marginLeft:"15%",textAlign:"right"}}>
          <div style={{marginRight:"2%",marginTop:"3%"}}>
            <WhiteDominusLogo/>
          </div>
        </div>

      </div>


    </ThemeProvider>
  );
}

export default App;
