import React, {useState, useEffect } from "react";
import {Grid, Select,MenuItem, FormControl, TextField, Button, Modal} from "@material-ui/core";
import {useStyles} from "../../themeApp";
import { Formik } from "formik";
import {validationKeySchema} from '../../validations/ValidationForms'
import {verificationKey, getCitiesDepartment} from "../../request/requests"
import {APP_COLORS as Colors} from "../../constants";
import SearchIcon from "@material-ui/icons/Search";

export function CheckOnlyKey(props){

    const {handlerSendingData, handlerShowErrorSnackbar, handlerErrorMessage, handlerShowInfoSnackbar, handlerInfoMessage} = props;

    const classes = useStyles();
    const {dataEdit} = props;
    const {handleShowModalKey} = props;
    const {handleIsEdit} = props;
    const {DataModal} = props;
    const {departments} = props;
    const {allCities} = props;
    const {editKey} = props;

    const [departmentSelected, setdepartmentSelected] = useState(null);
    const [cities, setcities] = useState([]);

    async function loadCitiesDepartment(data){
        handlerSendingData(true);
        setdepartmentSelected(data);
        if(data != null){
          await getCitiesDepartment(data).then((data) => {
            if (data.error) {
                handlerErrorMessage(data.message);
              handlerShowErrorSnackbar(true);
            } else {
              setcities(data.data);
            }
          });
        }else{
          setcities(null);
        }
        handlerSendingData(false);
      }

    async function sendVerificationKey(values, resetForm) {

        handlerSendingData(true);
    
        let action = await verificationKey(values.peopleData);
            if (!action.error) {
                /*handlerInfoMessage(action.message);
                handlerShowInfoSnackbar(true);

                action.data.peopleData.tax_responsible = action.data.peopleData.tax_responsible == 1 ? true : false;
                action.data.peopleData.has_retention = action.data.peopleData.has_retention == 1 ? true : false;
                let getDepartments = departments.filter(dep => dep.id == action.data.contactData.department)
                loadCitiesDepartment(getDepartments ? getDepartments[0] : null);
                let getCity = allCities.filter(city => city.id == action.data.contactData.residence_city);
                action.data.contactData.residence_city = getCity ? getCity[0] : null;

                DataModal(action.data, values.peopleData.edition_key , getDepartments ? getDepartments[0] : null);
                handleIsEdit(true);
                handleShowModalKey();*/
                handlerInfoMessage(action.message);
                handlerShowInfoSnackbar(true);
                action.data.peopleData.business_name = action.data.peopleData.name
                action.data.peopleData.tax_responsible = action.data.peopleData.tax_responsible == 1 ? true : false;
                action.data.peopleData.has_retention = action.data.peopleData.has_retention == 1 ? true : false;
                let getDepartments = departments.filter(dep => dep.id == action.data.contactData.department)
                loadCitiesDepartment(getDepartments ? getDepartments[0] : null);
                let getCity = allCities.filter(city => city.id == action.data.contactData.residence_city);
                action.data.contactData.residence_city = getCity ? getCity[0] : null;
                //objeto para enviar por medio de dataModal a validateSelectTypeDoc(e)
                let document_id = {
                    target: {
                        'value' : action.data.peopleData.document_type_id,
                        'showRut': false,
                    }
                };
                let names = action.data.peopleData.name
                let array_names = names.split(' ')
                if(array_names.length >= 2 ){
                    array_names.filter(word => word.length > 0)
                    action.data.peopleData.name = array_names[0] 
                    action.data.peopleData.optional_name = array_names[1]
                }
                let last_names = action.data.peopleData.last_name
                let array_last_names = last_names.split(' ')
                if(array_last_names.length >= 2 ){
                    array_last_names.filter(word => word.length > 0)
                    action.data.peopleData.last_name = array_last_names[0] 
                    action.data.peopleData.optional_last_name = array_last_names[1]
                }
                DataModal(action.data, values.peopleData.edition_key , getDepartments ? getDepartments[0] : null, document_id);
                handleIsEdit(true);
                handleShowModalKey();
            } else {
                /*handlerErrorMessage(action.message, departmentSelected);
                handlerShowErrorSnackbar(true);*/
                handlerErrorMessage(action.message, departmentSelected);
                handlerShowErrorSnackbar(true);
            }
            //handlerSendingData(false);
            handlerSendingData(false);
          
    }

    return (
        <Modal open={props.editKey} onClose={() => handleShowModalKey()} className={classes.modal}>

            <Grid container justify="center">

                <Formik
                    initialValues={{
                        peopleData: {
                        document_type_id: dataEdit ? dataEdit.document_type_id : "",
                        document: dataEdit ? dataEdit.document : "",
                        edition_key: ""
                        }
                    }}
                    validationSchema={validationKeySchema}
                    onSubmit={(values, { resetForm }) => sendVerificationKey(values, resetForm)}
                    >
                    {({ handleChange, handleBlur, handleSubmit, values, errors, touched}) => (
                        <Grid item id="form-container" md={4} xs={12}>

                            <Grid container direction="column" justify="center" spacing={2} className={classes.windowsModal} >
                                
                                <Grid item style={{ backgroundColor: Colors.primary, padding: 0, marginTop: 10, paddingBottom: 5, }} xs={12} md={12} >
                                    <Grid container justify="center">
                                        <h4 className={classes.formText}>
                                        Llave de edición
                                        </h4>
                                    </Grid>
                                </Grid>

                                <Grid container justify="center">
                                    <p>
                                    Enviamos una llave de edición a tu correo electrónico con el que te registraste
                                    </p>
                                </Grid>

                                <Grid container justify="center">
                                    <small>Por favor revisa tu bandeja de entrada</small>
                                </Grid>

                            

                                <Grid container direction="column" style={{ marginTop: 20 }} className={classes.formRow} justify="center" alignItems="center" >
                                    <Grid className={classes.item} xs={12} item md={12}>
                                        <FormControl>
                                            <TextField
                                            name="peopleData.edition_key"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                errors.peopleData &&
                                                errors.peopleData.edition_key &&
                                                touched.peopleData &&
                                                touched.peopleData.edition_key
                                            }
                                            helperText={
                                                errors.peopleData &&
                                                errors.peopleData.edition_key &&
                                                touched.peopleData &&
                                                touched.peopleData.edition_key
                                                ? errors.peopleData.edition_key
                                                : ""
                                            }
                                            label="Llave de edición"
                                            id="input-key"
                                            InputLabelProps={{
                                                required: true,
                                                color: "primary",
                                                error: false,
                                                type:"text"
                                            }}
                                            value={values.peopleData.edition_key}
                                            />
                                        </FormControl>
                                    </Grid>
                                    
                                </Grid>

                                
                                


                                <Grid container direction="row" style={{ marginTop: 30 }} justify="center" >
                                    <Button
                                        type="submit"
                                        id="btn-search"
                                        variant="contained"
                                        size="medium"
                                        color="primary"
                                        onClick={handleSubmit}
                                        startIcon={<SearchIcon />}
                                    >
                                        Validar
                                    </Button>
                                </Grid>

                            </Grid>
                        </Grid>
                    )}
                </Formik>
            </Grid>
        </Modal>
    );

}