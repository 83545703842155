import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { APP_COLORS as Colors, PAGE_TITLE } from './constants';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// Inyectar los colores al HTML
document.getElementsByTagName("body")[0].style["background-color"] = Colors.primary;
document.title = PAGE_TITLE;


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
