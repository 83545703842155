import React, {useState } from "react";
import {Grid, FormControl, TextField, Button, Modal} from "@material-ui/core";
import {useStyles} from "../../themeApp";
import { Formik } from "formik";
import {validationSaleSchema} from '../../validations/ValidationForms'
import {getSaleDetail} from "../../request/requests"
import {APP_COLORS as Colors} from "../../constants";
import SearchIcon from "@material-ui/icons/Search";

export function SearchSale(props){

    const {handlerSendingData, handlerShowErrorSnackbar, handlerErrorMessage, handlerShowInfoSnackbar, handlerInfoMessage} = props;

    const classes = useStyles();
    const {isBilling} = props;
    const {handleViewBilling} = props;
    const {handleSearchSale} = props;
    const {handleLoadSale} = props;
    const [sale, setSale] = useState(null);

    async  function sendSearchSale(values){

        handlerSendingData(true);
  
        let action = await getSaleDetail(values.billingData);

        var newSale = action;
  
        if (!action.error) {

            newSale.data.totalSale = 0;
            newSale.data.sale_code = values.billingData.sale_code;
            
            action.data.productref_sale.map((sale) => {
  
                sale.totalTax = 0;
    
                if(sale.taxes != null){
                sale.taxes.forEach(tax => {
                    sale.totalTax += tax.value;
                });
                }
    
                newSale.data.totalSale += sale.total;
                console.log(sale);
                return sale;
            });
          
          handleLoadSale(newSale.data);
          handleSearchSale();
          handleViewBilling();
  
          handlerInfoMessage(action.message);
          handlerShowInfoSnackbar(true);
        } else {
          handlerErrorMessage(action.message);
          handlerShowErrorSnackbar(true);
        }
        handlerSendingData(false);
        
    }

    return (
        <Modal open={isBilling} onClose={() => handleSearchSale()} className={classes.modal}>

            <Grid container justify="center">

                <Formik
                    initialValues={{
                    billingData: {
                        pcc: "",
                        receipt: "",
                        sale_code: ""
                    }
                    }}
                    validationSchema = {validationSaleSchema}
                    onSubmit={(values, { resetForm }) => sendSearchSale(values, resetForm)}
                >

                    {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched,}) => (
                    <Grid item id="form-container" md={4} xs={12}>
                        
                        <Grid container direction="column" justify="center" spacing={2} className={classes.windowsModal}>
                        
                        {/*TITULO VENTANA MODAL*/}
                        <Grid item
                            style={{
                            backgroundColor: Colors.primary,
                            padding: 0,
                            marginTop: 10,
                            paddingBottom: 5,
                            }}
                            xs={12}
                            md={12}
                        >
                            <Grid container justify="center">
                            <h4 className={classes.formText}>
                                Datos de venta
                            </h4>
                            </Grid>
                        </Grid>

                        {/*CAMPO FORMULARIO PCC*/}
                        <Grid
                            container
                            direction="column"
                            style={{ marginTop: 20 }}
                            className={classes.formRow}
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item xs={12} md={12} >
                            <FormControl>
                                <TextField
                                name="billingData.pcc"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                    errors.billingData &&
                                    errors.billingData.pcc &&
                                    touched.billingData &&
                                    touched.billingData.pcc
                                }
                                helperText={
                                    errors.billingData &&
                                    errors.billingData.pcc &&
                                    touched.billingData &&
                                    touched.billingData.pcc
                                    ? errors.billingData.pcc
                                    : ""
                                }
                                label="PCC"
                                id="input-pcc"
                                InputLabelProps={{
                                    required: true,
                                    color: "primary",
                                    error: false,
                                    type:"text"
                                }}
                                value={values.billingData.pcc}
                                />
                            </FormControl>
                            </Grid>
                            
                        </Grid>

                        {/*CAMPO FORMULARIO ID VENTA*/}
                        <Grid
                            container
                            direction="column"
                            style={{ marginTop: 20 }}
                            className={classes.formRow}
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item xs={12} md={12} >
                            <FormControl>
                                <TextField
                                name="billingData.receipt"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                    errors.billingData &&
                                    errors.billingData.receipt &&
                                    touched.billingData &&
                                    touched.billingData.receipt
                                }
                                helperText={
                                    errors.billingData &&
                                    errors.billingData.receipt &&
                                    touched.billingData &&
                                    touched.billingData.receipt
                                    ? errors.billingData.receipt
                                    : ""
                                }
                                label="Identificador de venta"
                                id="input-receipt"
                                InputLabelProps={{
                                    required: true,
                                    color: "primary",
                                    error: false,
                                    type:"text"
                                }}
                                value={values.billingData.receipt}
                                />
                            </FormControl>
                            </Grid>
                            
                        </Grid>

                        {/*CAMPO FORMULARIO CODIGO*/}
                        <Grid
                            container
                            direction="column"
                            style={{ marginTop: 20 }}
                            className={classes.formRow}
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item xs={12} md={12} >
                            <FormControl>
                                <TextField
                                name="billingData.sale_code"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                    errors.billingData &&
                                    errors.billingData.sale_code &&
                                    touched.billingData &&
                                    touched.billingData.sale_code
                                }
                                helperText={
                                    errors.billingData &&
                                    errors.billingData.sale_code &&
                                    touched.billingData &&
                                    touched.billingData.sale_code
                                    ? errors.billingData.sale_code
                                    : ""
                                }
                                label="Código"
                                id="input-sale_code"
                                InputLabelProps={{
                                    required: true,
                                    color: "primary",
                                    error: false,
                                    type:"text"
                                }}
                                value={values.billingData.sale_code}
                                />
                            </FormControl>
                            </Grid>
                            
                        </Grid>
                                
                        {/*BOTON CONSULTAR*/}
                        <Grid container direction="row" style={{ marginTop: 30 }} justify="center" >

                            <Button
                            type="submit"
                            id="btn-search"
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={handleSubmit}
                            startIcon={<SearchIcon/>}
                            >
                            Consultar
                            </Button>

                        </Grid>

                        </Grid>

                    </Grid>
                    )}
                </Formik>

            </Grid>

        </Modal>
    );

}