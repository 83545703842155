import React from "react";
import {withStyles} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {APP_COLORS as Colors} from "../../constants";

export function Alert(props) {
    return (<MuiAlert elevation={6} variant="filled" {...props} />);
}

export const SuccessAlert = withStyles({
    standardSuccess:{
      color:Colors.primary,
      background:Colors.light,
    },
  
  })(Alert);