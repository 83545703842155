import React from "react";
import { SvgIcon } from "@material-ui/core";
import coesco from "../../resources/icons/coesco.png";
import finalLogo from "../../resources/icons/logo_ia_2022.svg";
import whiteDominus from "../../resources/icons/dominusblanco.svg";

export function Logo(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <path d="M16.391 6.383c0 0 0 0 0 0s0 0 0 0z"></path>
      <path d="M16.394 6.38v0z"></path>
      <path d="M8.805 16.205c-0.125 0-0.244 0.015-0.361 0.038l0.111 0.372c0.081-0.015 0.166-0.023 0.253-0.023 0.474 0 0.887 0.238 1.134 0.605 0.125 0.183 0.206 0.398 0.23 0.631h0.387c-0.032-0.401-0.198-0.765-0.454-1.047-0.323-0.355-0.785-0.576-1.3-0.576z"></path>
      <path d="M7.435 17.961c0-0.576 0.355-1.067 0.858-1.271l-0.108-0.372c-0.663 0.25-1.137 0.89-1.137 1.643 0 0.471 0.186 0.896 0.489 1.213l0.259-0.288c-0.224-0.244-0.361-0.567-0.361-0.925z"></path>
      <path d="M8.805 19.055c0.314 0 0.596-0.134 0.797-0.346 0.183-0.195 0.297-0.459 0.297-0.747 0-0.14-0.026-0.273-0.076-0.398-0.16-0.407-0.555-0.695-1.018-0.695-0.605 0-1.093 0.488-1.093 1.093s0.491 1.093 1.093 1.093z"></path>
      <path d="M9.575 19.095c-0.218 0.148-0.483 0.238-0.771 0.238-0.302 0-0.582-0.099-0.808-0.265l-0.259 0.288c0.297 0.227 0.666 0.363 1.067 0.363 0.276 0 0.535-0.064 0.768-0.177 0.547-0.267 0.933-0.808 0.983-1.445h-0.387c-0.041 0.413-0.268 0.773-0.593 0.997z"></path>
      <path d="M22.096 16.789c-0.012-0.029-0.026-0.055-0.038-0.081-0.265-0.59-0.611-1.131-1.029-1.614-0.047-0.058-0.090-0.113-0.137-0.172-0.183-0.221-0.331-0.462-0.445-0.718-0.491-1.105-0.305-2.443 0.576-3.376-0.012 0-0.023 0-0.023 0-1.119 0.090-2.21 0.567-3.062 1.419 0 0-0.003 0.003-0.003 0.003-0.003-0.003-0.009-0.006-0.012-0.006 0-0.003-0.003-0.006-0.003-0.003-0.227-0.704-0.343-1.431-0.343-2.21 0-1.442 0.442-2.797 1.16-3.931-0.119-0.009-0.212-0.012-0.299-0.009-0.701 0.026-1.381 0.099-2.024 0.285-0.009 0-0.015 0.003-0.020 0.006 0 0 0 0 0 0s0 0 0 0c-2.347 0.675-4.208 2.486-4.952 4.804l0.026 0.012c0.904 0.465 1.623 1.175 2.041 2.166 0.172 0.41 0.294 0.867 0.352 1.375 0.058 0.494-0.090 1.067 0.070 1.559 0.102 0.32 0.515 0.756 0.814 1.085 0.238 0.267 0.817 0.611 0.811 1.085-0.009 0.579-0.48 0.384-0.678 0.881 0.032 0.215 0.119 0.413 0.172 0.587 0.076 0.253 0.073 0.454-0.305 0.567 0.704 0.285 0.041 0.861 0 1.355-0.035 0.372 0.265 0.695 0.201 1.085-0.145 0.942-1.346 0.948-2.3 0.948-0.241 0-0.811 0.003-1.454-0.198 0.032 0.041 0.067 0.081 0.102 0.122 1.166 1.259 2.821 2.056 4.661 2.094 0.047 0 0.090 0.003 0.137 0.003 3.591 0 6.505-2.899 6.534-6.484 0-0.020 0-0.038 0-0.055 0-0.919-0.189-1.791-0.529-2.582z"></path>
    </SvgIcon>
  );
}

export function IALogo(props) {
  return(
    <SvgIcon {...props} viewBox="0 0 36 32">
    <path d="M11.31 17.604c0 0.217 0.024 0.434 0.072 0.627 0.121 0.627 0.434 1.157 0.868 1.567l0.434-0.531c-0.41-0.41-0.675-1.013-0.675-1.664 0-0.145 0.024-0.289 0.048-0.434 0.169-0.844 0.772-1.519 1.543-1.784l-0.193-0.675c-1.206 0.41-2.098 1.543-2.098 2.894z"></path>
    <path d="M14.662 4.726c0.193 0.024 0.41 0.048 0.603 0.072l1.157-3.135c0.338-0.916-0.193-1.664-1.182-1.664h-2.363c-0.965 0-2.050 0.748-2.387 1.664l-1.302 3.617c1.809-0.506 3.69-0.699 5.474-0.555z"></path>
    <path d="M4.895 17.073l-4.799 13.239c-0.338 0.916 0.193 1.664 1.182 1.664h2.194c0.965 0 2.050-0.748 2.387-1.664l4.871-13.239c-1.158 0.024-3.4 0-5.836 0z"></path>
    <path d="M36.124 30.336l-10.514-28.672c-0.338-0.916-1.423-1.664-2.411-1.664h-3.448c-0.989 0-2.050 0.748-2.387 1.664l-1.182 3.28c3.569 0.723 6.366 2.942 6.849 7.066 0.096 0.868-0.169 1.857 0.121 2.701 0.169 0.555 0.892 1.302 1.423 1.881 0.41 0.458 1.423 1.061 1.399 1.881-0.024 1.013-0.844 0.675-1.182 1.519 0.145 0.916 0.868 1.664-0.241 2.002 1.23 0.506 0.072 1.495 0 2.363-0.048 0.651 0.458 1.206 0.338 1.881-0.241 1.64-2.339 1.64-4.003 1.64-0.868 0-4.099 0.024-5.932-3.014-0.579-0.94-0.699-2.508-0.892-3.979-1.326-0.121-2.411-1.037-2.821-2.267l-4.22 11.696c-0.338 0.916 0.193 1.664 1.158 1.664h26.791c0.965 0.024 1.495-0.723 1.157-1.64z"></path>
    <path d="M14.372 20.666c1.64 0 2.99-1.302 3.063-2.942h-0.699c-0.072 1.254-1.085 2.267-2.363 2.267-0.169 0-0.313 0-0.482-0.048-0.386-0.096-0.723-0.265-1.013-0.506l-0.434 0.531c0.434 0.362 0.989 0.603 1.592 0.675 0.096 0 0.217 0.024 0.338 0.024z"></path>
    <path d="M14.372 14.541c-0.265 0-0.506 0.048-0.748 0.096l0.217 0.651c0.169-0.048 0.362-0.072 0.531-0.072 1.278 0 2.291 1.013 2.363 2.243h0.699c-0.072-1.616-1.399-2.918-3.063-2.918z"></path>
    <path d="M12.25 17.604c0 0.989 0.675 1.833 1.616 2.050 0.169 0.048 0.338 0.072 0.531 0.072 1.182 0 2.122-0.965 2.122-2.122 0-1.182-0.965-2.122-2.122-2.122-1.037 0-1.905 0.748-2.074 1.736-0.048 0.121-0.072 0.265-0.072 0.386z"></path>
  </SvgIcon>
  );
}

export function CoescoLogo(props){
  return(
    <div>
      <img src={coesco}/>
    </div>
  )
}

export function FinalLogoIA(props){
  return(
    <div>
      <img src={finalLogo} style={{height:50,padding:0}}/>
    </div>
  );
}
//logo dominus blanco
export function WhiteDominusLogo(props){
  return(
    <div>
      <img src={whiteDominus} style={{height:80,padding:0}}/>
    </div>
  );
}