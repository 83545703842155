import MavenProRegular from '../../resources/fonts/MavenPro-Regular.ttf';
import MavenProBold from '../../resources/fonts/MavenPro-Bold.ttf';

export const mavenProRegular = {
    fontFamily: 'MavenProRegular',
    fontStyle:'normal',
    fontDisplay:'swap',
    src: `
        local('MavenPro'),
        local('MavenPro-Regular'),
        url(${MavenProRegular}) format('ttf')
    `
};

export const mavenProBold = {
    fontFamily: 'MavenProBold',
    fontStyle:'normal',
    fontDisplay:'swap',
    src: `
        local('MavenPro'),
        local('MavenPro-Bold'),
        url(${MavenProBold}) format('ttf')
    `
};