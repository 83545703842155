/**
 * Función para manejar las response de la API
 * @param {*Object} response Objeto response desde la API del Dominus 
 * @param {*Boolean} display Booleano para decidir si se quiere desplegar el mensaje en caso de que no haya errores
 */
export function handleError(response,display = false){
    if (response.error){
        if (response.error === 1){
            //Toast.show(response.msg, Toast.SHORT);
            return false;
        }else{
            if (display){
                //Toast.show(response.msg, Toast.SHORT);
            }
            return true
        }
    }else{
        if (response.data.messages.error === 1){
            //Toast.show(response.data.messages.msg, Toast.SHORT);
            return false;
        }else{
            if (display){
                //Toast.show(response.data.messages.msg, Toast.SHORT);
            }
            return true
        }
    }

}